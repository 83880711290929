<template>
  <div class="panel">
    <div>
      <h1>Бүсийн цагийн хуваарь</h1>
      <div>
        <span>
          Бүс:
          <strong>{{
            scheduleData.delivery_zone === "SOUTH_ZONE"
              ? "Хан-Уул бүс"
              : scheduleData.delivery_zone === "CENTRAL_ZONE"
              ? "Төвийн бүс"
              : "Яармаг бүс"
          }}</strong> </span
        ><br />
        <div style="margin-top:1%;" v-if="scheduleData.type !== 'default'">
          <div style="margin-top:1%;" v-if="isSearch">
            Хугацаа:
            <el-date-picker
              type="date"
              placeholder="Эхлэх огноо"
              v-model="start_date"
              @change="changedate"
              format="yyyy-MM-dd"
              class="mb10"
              size="small"
            ></el-date-picker>
            <el-date-picker
              type="date"
              placeholder="Дуусах огноо"
              v-model="end_date"
              @change="changedate"
              format="yyyy-MM-dd"
              class="mb10 ml5"
              size="small"
            ></el-date-picker>
          </div>
          <div v-else>
            Хугацаа:
            <el-date-picker
              type="date"
              placeholder="Эхлэх огноо"
              v-model="scheduleData.start_date"
              @change="changedate"
              format="yyyy-MM-dd"
              class="mb10"
              size="small"
            ></el-date-picker>
            <el-date-picker
              type="date"
              placeholder="Дуусах огноо"
              v-model="scheduleData.end_date"
              @change="changedate"
              format="yyyy-MM-dd"
              class="mb10 ml5"
              size="small"
            ></el-date-picker>
          </div>
        </div>
      </div>
      <div
        v-if="scheduleData.type !== 'default'"
        style="display:flex; float:right;"
      >
        <div class="info ml15">
          <div
            style="background-color:#67c244; width:10px; height:10px; border-radius:20px"
          ></div>
          <span class="ml5">Хэвийн</span>
        </div>
        <div class="info ml15">
          <div
            style="background-color:#f9ae56; width:10px; height:10px; border-radius:20px"
          ></div>
          <span class="ml5">Дундаж</span>
        </div>
        <div class="info ml15">
          <div
            style="background-color:#fc6d85; width:10px; height:10px; border-radius:20px"
          ></div>
          <span class="ml5">Ачаалал ихтэй</span>
        </div>
      </div>
      <table style="width:100%;">
        <thead>
          <tr>
            <th>Өдөр</th>
            <th
              style="padding:0 2%; width:160px;"
              v-for="(time, timeIndex) in times"
              :key="timeIndex"
            >
              {{ time.time }}
            </th>
          </tr>
        </thead>
        <tbody v-if="isSearch">
          <tr v-for="(pred, predIndex) in prediction" :key="predIndex">
            <td style="padding:0 2%; width:120px;">{{ pred.day }}</td>
            <td v-for="(week, weekIndex) in weeks" :key="weekIndex">
              <div v-for="(sched, schedIndex) in schedule" :key="schedIndex">
                <div
                  v-if="
                    JSON.stringify(sched.hours) === JSON.stringify(week) &&
                      sched.day === pred.day
                  "
                >
                  <div v-for="(time, timeIndex) in pred.times" :key="timeIndex">
                    <div
                      v-if="
                        JSON.stringify(time.hours) ===
                          JSON.stringify(sched.hours)
                      "
                    >
                      <div style="display: flex; flex-direction: row">
                        <div
                          style="display: flex; flex-direction: column; gap: 5px; width: 100%; background-color: #fff; font-size: 10px"
                        >
                          <span>Хуваарьт цагт</span>
                          <el-input
                            style="width:100%;"
                            size="mini"
                            :disabled="sched.driver_count > 0"
                            v-model="sched.additional_price"
                          />
                        </div>
                        <div
                          style="display: flex; flex-direction: column; gap: 5px; width: 100%; background-color: #fff; font-size: 10px"
                        >
                          <span>Нэмэлт</span>
                          <el-input
                            style="width:100%;"
                            size="mini"
                            :disabled="sched.driver_count > 0"
                            v-model="sched.planned_additional_price"
                          />
                        </div>
                      </div>
                      <div
                        style="display:flex; justify-content:space-between; margin: 3px 8%;"
                      >
                        <span>Ж: {{ time.driver_count }}</span>
                        <span>Т: {{ time.prediction_count }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="(day, dayIndex) in days" :key="dayIndex">
            <td style="padding:0 2%; width:120px;">{{ day }}</td>
            <td v-for="(week, weekIndex) in weeks" :key="weekIndex">
              <div v-for="(sched, schedIndex) in schedule" :key="schedIndex">
                <div
                  v-if="
                    JSON.stringify(sched.hours) === JSON.stringify(week) &&
                      sched.day === day
                  "
                  :style="
                    JSON.stringify(sched.hours) === JSON.stringify(first)
                      ? sched.prediction_count < 11
                        ? 'background-color:#67c244;'
                        : sched.prediction_count < 21 &&
                          sched.prediction_count > 10
                        ? 'background-color:#f9ae56;'
                        : 'background-color:#fc6d85;'
                      : JSON.stringify(sched.hours) === JSON.stringify(second)
                      ? sched.prediction_count < 6
                        ? 'background-color:#67c244;'
                        : sched.prediction_count < 11 &&
                          sched.prediction_count > 5
                        ? 'background-color:#f9ae56;'
                        : 'background-color:#fc6d85;'
                      : JSON.stringify(sched.hours) === JSON.stringify(third)
                      ? sched.prediction_count < 36
                        ? 'background-color:#67c244;'
                        : sched.prediction_count < 71 &&
                          sched.prediction_count > 35
                        ? 'background-color:#f9ae56;'
                        : 'background-color:#fc6d85;'
                      : JSON.stringify(sched.hours) === JSON.stringify(fourth)
                      ? sched.prediction_count < 86
                        ? 'background-color:#67c244;'
                        : sched.prediction_count < 171 &&
                          sched.prediction_count > 85
                        ? 'background-color:#f9ae56;'
                        : 'background-color:#fc6d85;'
                      : JSON.stringify(sched.hours) === JSON.stringify(fifth)
                      ? sched.prediction_count < 61
                        ? 'background-color:#67c244;'
                        : sched.prediction_count < 121 &&
                          sched.prediction_count > 60
                        ? 'background-color:#f9ae56;'
                        : 'background-color:#fc6d85;'
                      : JSON.stringify(sched.hours) === JSON.stringify(sixth)
                      ? sched.prediction_count < 131
                        ? 'background-color:#67c244;'
                        : sched.prediction_count < 261 &&
                          sched.prediction_count > 130
                        ? 'background-color:#f9ae56;'
                        : 'background-color:#fc6d85;'
                      : JSON.stringify(sched.hours) === JSON.stringify(seventh)
                      ? sched.prediction_count < 41
                        ? 'background-color:#67c244;'
                        : sched.prediction_count < 81 &&
                          sched.prediction_count > 40
                        ? 'background-color:#f9ae56;'
                        : 'background-color:#fc6d85;'
                      : ''
                  "
                >
                  <div style="display: flex; flex-direction: row">
                    <div
                      style="display: flex; flex-direction: column; gap: 5px; width: 100%; background-color: #fff; font-size: 10px"
                    >
                      <span>Хуваарьт цагт</span>
                      <el-input
                        style="width:100%;"
                        size="mini"
                        :disabled="
                          sched.driver_count > 0 ||
                            scheduleData.type === 'default'
                        "
                        v-model="sched.additional_price"
                      />
                    </div>
                    <div
                      style="display: flex; flex-direction: column; gap: 5px; width: 100%; background-color: #fff; font-size: 10px"
                    >
                      <span>Нэмэлт</span>
                      <el-input
                        style="width:100%;"
                        size="mini"
                        v-model="sched.planned_additional_price"
                      />
                    </div>
                  </div>
                  <div
                    v-if="scheduleData.type !== 'default'"
                    style="display:flex; justify-content:space-between; margin: 3px 8%;"
                  >
                    <span>Ж: {{ sched.driver_count }}</span>
                    <span>Т: {{ sched.prediction_count }}</span>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <el-button
        @click="save"
        v-if="zoneType === 'additional'"
        style="display:flex; justify-items: end;"
        type="success"
        >Хадгалах</el-button
      >
    </div>
  </div>
</template>
<script>
import services from "../../helpers/services";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import * as timezone from "dayjs/plugin/timezone";
import "dayjs/locale/mn";
dayjs.locale("mn");
dayjs.extend(timezone);
dayjs.extend(utc);
export default {
  created() {
    if (this.$route.params.id) {
      this.detailIncentives(this.$route.params.id);
    }
  },
  methods: {
    changedate() {
      if (this.isSearch) {
        this.scheduleData.start_date = this.start_date;
        this.scheduleData.end_date = this.end_date;
      } else {
        this.start_date = this.scheduleData.start_date;
        this.end_date = this.scheduleData.end_date;
      }
      this.getPredictionByWeek();
    },
    async getPredictionByWeek() {
      if (
        ["", null, undefined].includes(this.scheduleData.start_date) ||
        ["", null, undefined].includes(this.scheduleData.end_date)
      ) {
        return this.$notify.error({
          title: "Error",
          message: "Он сар оруулна уу!"
        });
      } else {
        this.loading = true;
        const payload = {
          start_date: this.isSearch
            ? dayjs(this.start_date)
                .tz("Asia/Ulaanbaatar")
                .format("YYYY-MM-DD")
            : dayjs(this.scheduleData.start_date)
                .tz("Asia/Ulaanbaatar")
                .format("YYYY-MM-DD"),
          end_date: this.isSearch
            ? dayjs(this.end_date)
                .tz("Asia/Ulaanbaatar")
                .format("YYYY-MM-DD")
            : dayjs(this.scheduleData.end_date)
                .tz("Asia/Ulaanbaatar")
                .format("YYYY-MM-DD"),
          delivery_zone: this.scheduleData.delivery_zone
        };
        await services.getPredictionByWeek(payload).then(prediction => {
          if (prediction.data.status === "success") {
            this.prediction = prediction.data.data;
            this.detailIncentives(this.$route.params.id);
            this.isSearch = true;
            this.loading = false;
          } else {
            this.$message({
              type: "error",
              message: prediction.data.message
            });
            this.loading = false;
          }
        });
      }
    },
    async detailIncentives(id) {
      this.loading = true;
      await services.getDetailIncentives(id).then(res => {
        if (res.data.status === "success") {
          let ex = [];
          let dayss = [];
          this.weeks = [];
          this.days = [];
          this.scheduleData = res.data.data;
          this.zoneType = res.data.data.type;
          this.schedule = res.data.data.schedule;
          res.data.data.schedule.map(sched => {
            if (!ex.includes(JSON.stringify(sched.hours)))
              ex.push(JSON.stringify(sched.hours));
            if (!dayss.includes(sched.day)) dayss.push(sched.day);
          });
          ex.forEach(el => this.weeks.push(JSON.parse(el)));
          dayss.map(el => this.days.push(el));
          this.weeks = this.weeks.sort((a, b) => a[0] - b[0]);
          this.start_date = this.isSearch
            ? this.start_date
            : this.scheduleData.start_date;
          this.end_date = this.isSearch
            ? this.end_date
            : this.scheduleData.end_date;
        } else {
          this.$message({
            type: "error",
            message: res.data.message
          });
        }
        this.loading = false;
      });
    },
    save() {
      const payload = {
        delivery_zone: this.scheduleData.delivery_zone,
        status: "active",
        start_date: this.isSearch
          ? dayjs(this.start_date)
              .tz("Asia/Ulaanbaatar")
              .format("YYYY-MM-DD")
          : dayjs(this.scheduleData.start_date)
              .tz("Asia/Ulaanbaatar")
              .format("YYYY-MM-DD"),
        end_date: this.isSearch
          ? dayjs(this.end_date)
              .tz("Asia/Ulaanbaatar")
              .format("YYYY-MM-DD")
          : dayjs(this.scheduleData.end_date)
              .tz("Asia/Ulaanbaatar")
              .format("YYYY-MM-DD"),
        schedule: this.schedule
      };
      services.updateIncentives(this.$route.params.id, payload).then(res => {
        if (res.data.status === "success") {
          this.$message({
            type: "success",
            message: "Амжилттай..."
          });
          this.$router.push({
            name: "IncentivesList"
          });
        } else {
          this.$message({
            type: "error",
            message: res.data.message
          });
        }
      });
    }
  },
  data() {
    return {
      loading: false,
      start_date: "",
      end_date: "",
      zone: "CENTRAL_ZONE",
      scheduleData: {},
      weeks: [],
      days: [],
      prediction: [],
      isSearch: false,
      first: [0, 1, 2, 3, 4, 5],
      second: [6, 7, 8, 9],
      third: [10, 11],
      fourth: [12, 13, 14],
      fifth: [15, 16],
      sixth: [17, 18, 19, 20],
      seventh: [21, 22, 23],
      times: [
        {
          time: "0-5 цаг"
        },
        {
          time: "6-9 цаг"
        },
        {
          time: "10-11 цаг"
        },
        {
          time: "12-14 цаг"
        },
        {
          time: "15-16 цаг"
        },
        {
          time: "17-20 цаг"
        },
        {
          time: "21-23 цаг"
        }
      ],
      zoneIncentivesData: [
        {
          name: "Үндсэн",
          desc: ""
        },
        {
          name: "Нэмэлт",
          desc: ""
        }
      ],
      schedule: [],
      zoneType: ""
    };
  }
};
</script>
<style scoped>
.image {
  width: 120px;
  height: 100px;
}
.info {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
table thead tr th {
  background-color: #e0e0e0;
  font-weight: normal;
  padding: 2% 10px;
}
table tbody tr td {
  border: 1px solid #e0e0e0;
  text-align: center;
  padding: 5px 0px;
}
table tbody tr td.hour-td {
  background-color: #e0e0e0;
  padding: 5px 5px;
}
</style>
